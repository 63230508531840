export const LST_SLCTD_VERSE_LENGTH = 15;
export const BOX_SHADOW_VALUE =
"0px 3px 1px -2px rgba(0, 0, 0, 0.3), 0px 3px 3px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)";
export const BX_SHDW_BIBLE_PGE_VALUE = "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)";
export const TXT_SHADOW_VALUE = `1px 1px 2px rgba(0, 0, 0, 0.1),2px 2px 4px rgba(0, 0, 0, 0.2),3px 3px 6px rgba(0, 0, 0, 0.1)`;
export const BOOKS_BORDER_RDS = `5px 0px 0px 5px`
export const CHAPTER_BORDER_RDS = `0px 5px 5px 0px`;
export const BRIGHT_BLUE = "#3a65ff";
export const DEEP_BLUE = "rgb(3,27,114)";
export const SWIPE_ICON_COLOR = "white";
export const SWIPE_ICON_OPC="0.8"
