import React, { useContext } from "react";
import "./VersesShimmerUi.css"; 
import { BookContext } from "../../context/BibleProvider";
const Shimmer = () => {
  const { ifMobile, ifTablet, ifLargerDevice, ifMediumDevice } =
    useContext(BookContext);
  return (
    <div className="shimmer-wrapper">
      {ifMobile && (
        <>
          <div className="shimmer"></div>
          {Array.from({ length: 14 }).map((_, index) => (
            <div key={index} className="line"></div>
          ))}
        </>
      )}
      {ifTablet && (
        <>
          <div className="shimmer"></div>
          {Array.from({ length: 20 }).map((_, index) => (
            <div key={index} className="line"></div>
          ))}
        </>
      )}
      {ifMediumDevice && (
        <>
          <div className="shimmer"></div>
          {Array.from({ length: 22 }).map((_, index) => (
            <div key={index} className="line"></div>
          ))}
        </>
      )}
      {ifLargerDevice && (
        <>
          <div className="shimmer"></div>
          {Array.from({ length: 25 }).map((_, index) => (
            <div key={index} className="line"></div>
          ))}
        </>
      )}
    </div>
  );
};

export default Shimmer;
